import React from 'react';
import css from './AddonsSectionMaybe.module.css';
import { addonsTypeOptions } from '../../util/general';

const AddonsSectionMaybe = ({ listing }) => {
  const upsellingItems = listing?.attributes?.publicData?.upsellingItems;

  if (!upsellingItems || upsellingItems?.length < 1) {
    return null;
  }
  return (
    <div className={css.sectionAddons}>
      <p className={css.sectionTitle}>Add ons</p>
      <div className={css.addonsWrapper}>
        {upsellingItems.map(item => {
          const priceLabel = item?.priceAmount / 100;
          const typeLabel = addonsTypeOptions.find(i => i.key === item.type)?.label;
          return (
            <div className={css.item}>
              <span className={css.itemSection}>
                {'• '}
                {item.label}
              </span>
              <span className={css.itemSection}>{`($${priceLabel} / ${typeLabel})`}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AddonsSectionMaybe;
