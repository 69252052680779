const axios = require('axios');

export const getDiscountLabel = (min, max) => {
  let discountLabel = null;
  if (min === 1 && max === 1) {
    discountLabel = 'Single night rental';
  }

  if (min > 1 && max <= 4) {
    discountLabel = `${min}-${max} night rental`;
  }

  if (min > 4) {
    discountLabel = `${min}+ night rental`;
  }

  return discountLabel;
};

export const getShortAddress = address => {
  if (!address) {
    return '';
  }

  const splittedAddress = address.split(',');
  if (splittedAddress?.length > 2) {
    return (
      splittedAddress[splittedAddress.length - 2] +
      ', ' +
      splittedAddress[splittedAddress.length - 1]
    );
  } else {
    return address;
  }
};

export const setCityStatePair = async (address, setter = () => {}) => {
  const accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
  const baseUrl = 'https://api.mapbox.com/geocoding/v5/mapbox.places/';
  const url = `${baseUrl}${encodeURIComponent(address)}.json?access_token=${accessToken}`;

  const response = await axios.get(url);

  if (response.data && response.data.features && response.data.features.length > 0) {
    const placeData = response.data.features[0].context;
    let city = '';
    let state = '';

    for (let i = 0; i < placeData.length; i++) {
      if (placeData[i].id.startsWith('place')) {
        city = placeData[i].text;
      } else if (placeData[i].id.startsWith('region')) {
        state = placeData[i].text;
      }
    }

    if (!city || !state) {
      // Extract from place_name if city or state is missing
      const placeName = response.data.features[0].place_name;
      const splitName = placeName.split(', ');

      if (splitName.length >= 3) {
        city = splitName[0];
        state = splitName[1];
      }
    }

    if (city && state) {
      setter(`${city}, ${state}`);
      return;
    } else {
      setter('');
      return;
    }
  } else {
    setter('');
    return;
  }
};
