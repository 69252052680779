import React from 'react';
import css from './MissingPmModal.module.css';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';

const MissingPmModal = props => {
  const { isOpen, setIsOpen, history } = props;

  const sendToPmPage = () => {
    window.sessionStorage.setItem('listing_redirect_url', window.location.pathname);
    history.push('/account/payment-methods');
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      onManageDisableScrolling={() => {}}
    >
      <center>
        <h2>You need to have a saved payment method to proceed with your booking</h2>
      </center>
      <Button onClick={sendToPmPage}>Add payment method</Button>
    </Modal>
  );
};

export default MissingPmModal;
