import React from 'react';
import css from './DetailsSection.module.css';
import { includedOptions } from '../../util/general';

const DetailsSection = props => {
  const { listing } = props;
  const publicData = listing?.attributes?.publicData || {};

  const getStringFromArrayValues = (selected, options) => {
    let finalString = '';
    selected.map((o, index) => {
      const foundLabel = options.find(y => y.key === o)?.label;
      if (index === selected.length - 1) {
        finalString += foundLabel;
      } else {
        finalString += `${foundLabel}, `;
      }
    });

    return finalString;
  };

  const rentalFeaturesItems = [
    // {
    //   label: 'Comes with',
    //   value: getStringFromArrayValues(publicData?.included, includedOptions),
    //   show: publicData?.included?.length > 0,
    // },
    {
      label: 'Model year',
      value: publicData?.modelYear,
      show: !!publicData?.modelYear,
    },
    {
      label: 'Vehicle make',
      value: publicData?.vehicleMake,
      show: !!publicData?.vehicleMake,
    },
    {
      label: 'Vehicle model',
      value: publicData?.model,
      show: !!publicData?.model,
    },
    {
      label: 'Available Seats',
      value: publicData?.availableSeats,
      show: !!publicData?.availableSeats,
    },
    {
      label: 'Total tow weight',
      value: publicData?.towWeight,
      show: !!publicData?.towWeight,
    },
    {
      label: 'Trailer hitch size',
      value: publicData?.hitchSize,
      show: !!publicData?.hitchSize,
    },
    // {
    //   label: 'Rental cash value',
    //   value: `$${publicData?.cashValueOfRental}`,
    //   show: !!publicData?.cashValueOfRental,
    // },
  ];

  const rulesAndFeesItems = [
    {
      label: 'Sand dunes allowed?',
      value: publicData?.dunesAllowed,
      show: !!publicData?.dunesAllowed,
    },
    {
      label: 'Rock crawling allowed?',
      value: publicData?.rockCrowlingAllowed,
      show: !!publicData?.rockCrowlingAllowed,
    },
    {
      label: 'Minimum nights in order to book',
      value: publicData?.minNight,
      show: !!publicData?.minNight,
    },
    {
      label: 'Cleaning Fee (charged if brought back dirty)',
      value: `$${publicData?.cleaningFee}`,
      show: !!publicData?.cleaningFee,
    },
    {
      label: 'Mileage limit per night',
      value: publicData?.mileageLimit,
      show: !!publicData?.mileageLimit,
    },
    // {
    //   label: 'Instabook?',
    //   value: publicData?.instabookAllowed,
    //   show: !!publicData?.instabookAllowed,
    // },
  ];
  return (
    <div className={css.wrapper}>
      <h2 className={css.title}>{'Rig Details'}</h2>

      <div className={css.infoItemsWrapper}>
        {rentalFeaturesItems
          .filter(x => x?.show)
          .map(i => {
            return (
              <div className={css.itemWrapper}>
                <div className={css.itemLabel}>{i?.label}</div>
                <div className={css.itemValue}>{i?.value}</div>
              </div>
            );
          })}
      </div>
      <p className={css.sectionTitle}>Rules and Fees</p>
      <div className={css.infoItemsWrapper}>
        {rulesAndFeesItems
          .filter(x => x?.show)
          .map(i => {
            return (
              <div className={css.itemWrapper}>
                <div className={css.itemLabel}>{i?.label}</div>
                <div className={css.itemValue}>{i?.value}</div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default DetailsSection;
