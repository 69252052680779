import React from 'react';
import css from './DiscountsSection.module.css';
import { getDiscountLabel } from './utils';
const DiscountsSection = props => {
  const { listing } = props;

  const discounts = listing?.attributes?.publicData?.multiDayDiscounts || [];

  if (discounts?.length < 1) {
    return null;
  }

  return (
    <div className={css.wrapper}>
      <p className={css.title}>Discounts</p>
      <div className={css.infoItemsWrapper}>
        {discounts.map(i => {
          const discountLabel = getDiscountLabel(Number(i?.minNights), Number(i?.maxNights));

          return (
            <div className={css.itemWrapper}>
              <div className={css.itemLabel}>{discountLabel}</div>
              <div className={css.itemValue}>{`Price per night: $${i?.pricePerNight}`}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DiscountsSection;
